import React , { useState, useEffect }  from 'react'
import './NetSegmentalResult.css'
import SegmentalPieChart03 from './SegmentalPieChart03'
import SegmentalPieChart04 from './SegmentalPieChart04'
import ScrollTrigger from 'react-scroll-trigger';
import AOS from 'aos';
import 'aos/dist/aos.css';

function NetSegmentalResult() {

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);


    const [windowsWidthType, setWindowsWidthType] = useState('empty');
    const [mountChart, setMountChart] = useState(false);

  return (
    <div className='Net-segmental-result-main-container'>
        <h1>Net Segmental Result</h1>
        <ScrollTrigger onEnter={()=>setMountChart(true)} triggerOnce>
            {mountChart &&
                <div className='Net-segmental-result-pie-chart-container'>
                    <div className='Net-segmental-result-pie-chart' data-aos="flip-right" data-aos-duration="1000" data-aos-once='true'>
                        <div className='Net-segmental-result-pie-chart-header'>
                            <p>Q1 2024 / 25</p>
                            <h3>Segmental Profit</h3>
                            <div className='Net-segmental-result-pie-chart-header-underline'></div>
                        </div>
                        <div className='Net-segmental-result-pie-chart-sub-container'>
                        
                                <>
                                    <div>
                                        <SegmentalPieChart03 
                                            windowsWidthType= {windowsWidthType}
                                            setWindowsWidthType = {setWindowsWidthType}
                                        />
                                    </div>
                                    {(windowsWidthType == 'xsmall' ||  windowsWidthType == 'small') && 
                                        <div className='Net-segmental-result-pie-chart-text'>
                                            <ul>
                                                <li>A : Crop Solutions</li>
                                                <li>B : Agri Produce</li>
                                                <li>C : Livestock Solution</li>
                                                <li>D : Industrial Solution</li>
                                                <li>E : Health & Personal Care</li>
                                            </ul>
                                        </div>
                                    }
                                </>
                        
                        </div>
                    </div>
                    <div className='Net-segmental-result-pie-chart' data-aos="flip-left" data-aos-duration="1000" data-aos-once='true'>
                        <div className='Net-segmental-result-pie-chart-header'>
                            <p>Q1 2023 / 24</p>
                            <h3>Segmental Profit</h3>
                            <div className='Net-segmental-result-pie-chart-header-underline'></div>
                        </div>
                        <div className='Net-segmental-result-pie-chart-sub-container'>
                            <div>
                                <SegmentalPieChart04
                                    windowsWidthType= {windowsWidthType}
                                    setWindowsWidthType = {setWindowsWidthType}
                                />
                            </div>
                            {(windowsWidthType == 'xsmall' ||  windowsWidthType == 'small') && 
                                <div className='Net-segmental-result-pie-chart-text'>
                                    <ul>
                                        <li>A : Crop Solutions</li>
                                        <li>B : Agri Produce</li>
                                        <li>C : Livestock Solution</li>
                                        <li>D : Industrial Solution</li>
                                        <li>E : Health & Personal Care</li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </ScrollTrigger>
    </div>
  )
}

export default NetSegmentalResult;
