import React, { useRef,useState, useEffect } from 'react';
import * as d3 from 'd3';
import './PieChart.css';

function SegmentalPieChart04({windowsWidthType, setWindowsWidthType}) {
    const svgRef04 = useRef();

    const [chartWidth, setChartWidth] = useState(430);
    const [chartHeight, setChartHeight] = useState(250);


    const updateWidthType = () => {
        if (window.innerWidth > 550) {
          setWindowsWidthType('large');
          setChartWidth(430)
          setChartHeight(250)
        } else if (window.innerWidth > 475 && window.innerWidth <= 550) {
          setWindowsWidthType('medium');
          setChartWidth(350)
          setChartHeight(180)
        } else if (window.innerWidth > 370 && window.innerWidth <= 475) {
          setWindowsWidthType('small');
          setChartWidth(300)
          setChartHeight(180)
        } else {
          setWindowsWidthType('xsmall');
          setChartWidth(250)
          setChartHeight(180)
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            updateWidthType();
            window.addEventListener('resize', updateWidthType);
        }

        return () => {
            window.removeEventListener('resize', updateWidthType);
        };
    }, []); 

    useEffect(() => {
        const radius = Math.min(chartWidth, chartHeight) / 2;
        const color = ['rgba(19, 40, 146, 1)', 'rgba(0, 69, 165, 1)','rgba(0, 105, 192, 1)', 'rgba(0, 138, 197, 1)','rgba(0, 169, 181, 1)'];
        const data = [41.40, 6.9, 21.1, 9.8, 20.8];
        const titles= ['Crop Solutions', 'Agri Produce', 'Livestock Solution', 'Industrial Solution', 'Health & Personal Care']
        const titlesSmall = ['A', 'B', 'C', 'D', 'E']

        d3.select(svgRef04.current).selectAll('*').remove();
        //Chart
        const svg = d3.select(svgRef04.current)
            .attr("width", chartWidth)
            .attr("height", 250)
            .append("g")
            .attr("transform", `translate(${chartWidth / 2},${250 / 2})`)

        const pie = d3.pie().sort(null).value(d => d);
        const arc = d3.arc()
            .innerRadius(radius * 0.5)
            .outerRadius(radius * 0.8);
        const outerArc = d3.arc()
            .outerRadius(radius * 0.9)
            .innerRadius(radius * 0.9);

        // slices
        svg.selectAll('path')
            .data(pie(data))
            .enter()
            .append('path')
            .attr('fill', (d, i) => color[i])
            .attr('d', arc)
            .each(function(d) { this._current = d; })
            .transition()
            .duration(1000)
            .attrTween("d", function(d) {
                const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
                return function(t) {
                    return arc(interpolate(t));
                };
            })
            .attr('class', (d,i)=>`chart-group-02 ${i} chart-genrel-class`)

        // Line
        svg.append("g").attr("class", "lines");

        svg.select(".lines")
        .selectAll("polyline")
        .data(pie(data))
        .enter()
        .append("polyline")
        .attr('points', function(d) {
            const start = arc.centroid(d);
            const end = outerArc.centroid(d);
            end[0] = radius * 10 * (midAngle(d) < Math.PI ? 1 : -1);
    
            return [start, outerArc.centroid(d), end];
        })
        .attr('stroke', (d, i) => color[i])
        .attr('stroke-width', 2)
        .attr('fill', 'none')  
        .style("opacity", 0)
        .transition()
        .duration(1000)
        .attr('class', (d,i)=>`chart-group-02 ${i} chart-genrel-class`)
        .style("opacity", 0.5);

        // Labels
        svg.selectAll(".labels")
            .data(pie(data))
            .enter()
            .append("text")
            .each(function(d, i) {
                const text = d3.select(this);
                text.append("tspan")
                    .attr("x", function(d){
                        if(windowsWidthType == 'large'){
                            return midAngle(d) < Math.PI ? '7.45em' : '-7.45em'
                        }else if(windowsWidthType == 'medium'){
                            return midAngle(d) < Math.PI ? '6.9em' : '-6.9em'
                        }else if(windowsWidthType == 'small'){
                            return midAngle(d) < Math.PI ? '4.9em' : '-4.9em'
                        }else if(windowsWidthType == 'xsmall'){
                            return midAngle(d) < Math.PI ? '3em' : '-3em'
                        }
                    })
                    .attr('dy','-0.5em')
                    .text(function(d){
                        if(windowsWidthType == 'small' || windowsWidthType == 'xsmall' ){
                            return titlesSmall[i];
                        }else{
                            return titles[i];
                        }
                        
                    })
                    .style('text-anchor', function(d){
                        return midAngle(d) <Math.PI ? 'end' : 'start' 
                    })
        
                text.append("tspan")
                    .attr("x", function(d){
                        if(windowsWidthType == 'large'){
                            return midAngle(d) < Math.PI ? '7.4em' : '-7.4em';
                        }else if(windowsWidthType == 'medium'){
                            return midAngle(d) < Math.PI ? '6.8em' : '-6.9em'
                        }else if(windowsWidthType == 'small'){
                            return midAngle(d) < Math.PI ? '4.9em' : '-4.9em'
                        }else if(windowsWidthType == 'xsmall'){
                            return midAngle(d) < Math.PI ? '3.1em' : '-3em'
                        }
                    })
                    .attr("dy", function(d){
                        return midAngle(d) < Math.PI ? "1.7em" : '1.6em';
                    })
                    .text(d.data + '%')
                    .style('text-anchor', function(d){
                        return midAngle(d) <Math.PI ? 'end' : 'start' 
                    })
                    .attr("class", "data-tspan")
            })
            .attr("transform", function(d) {
                const pos = outerArc.centroid(d);
                pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
                return `translate(${pos})`;
            })
            .style("opacity", 0)
            .transition()
            .duration(1000)
            .style("opacity", 1)
            .attr('class', (d,i)=>`chart-group-02 ${i} chart-genrel-class`)
            .style("text-anchor", function(d) {
                return midAngle(d) < Math.PI ? "start" : "end";
            });
        
        d3.selectAll(".data-tspan")
            .each(function(d, i) {
                d3.select(this)
                    .style("fill", color[i]);
            });

        function midAngle(d) {
            return d.startAngle + (d.endAngle - d.startAngle) / 2;
        }

        let allElements;
        const test =  setTimeout(() => {
            allElements = document.querySelectorAll('.chart-group-02');

            console.log(allElements)
            const handleMouseEnter = (e) => {
                allElements.forEach(path => {
                    if(path.classList[1] !== e.target.classList[1]){
                            path.style.filter = 'blur(2px)';
                    }else{
                        path.style.filter = 'none';
                    }
                });
            };
        
            const handleMouseLeave = () => {
                allElements.forEach(path => {
                    path.style.filter = 'none';
                });
            };
        
            allElements.forEach(path => {
            path.addEventListener('mouseenter', handleMouseEnter);
            path.addEventListener('mouseleave', handleMouseLeave);
            });
        
            return () => {
                allElements.forEach(path => {
                    path.removeEventListener('mouseenter', handleMouseEnter);
                    path.removeEventListener('mouseleave', handleMouseLeave);
                });
                clearTimeout(test);
            };
        }, 1000);

    }, [windowsWidthType]);

    return (
        <div className='pie-container'>
            <svg ref={svgRef04}></svg>
        </div>
    );
}

export default SegmentalPieChart04;
