import React from 'react'
import './FinancialMainBanner.css'
import FinancialImage from "../../images/financialPageBanner.webp"

export default function FinancialMainBanner() {
  return (
    <div>
      <div>
        <div className="financial">
          <img className="financial-home-image" alt="" src={FinancialImage} />
          <div className="financial-image-text-box">
            <div className="financial-image-background-text">
              <h1 className="financial-image-topic">
                Financial Highlights
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
