import React from "react";
import "./FinancialHighlightsQ.css";

function FinancialHighlightsQ() {
  return (
    <div className="financial-h-q-main-container">
      <div
        className="financial-h-q-sub-container"
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-once="true"
      >
        <h3>
          Financial Highlights for Q1 <br />
          FY 2023/24
        </h3>
        <div className="financial-h-q-table-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  Crop Solutions
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  Agri Produce
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  Livestock Solutions
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="900"
                  data-aos-once="true"
                >
                  Industrial Solutions
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  Healthcare & Personal
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1500"
                  data-aos-once="true"
                >
                  Group
                </th>
              </tr>
            </thead>
            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr data-aos="fade-up" data-aos-delay="500" data-aos-once="true">
                <td>ROA</td>
                <td>7%</td>
                <td>2%</td>
                <td>5%</td>
                <td>2%</td>
                <td>3%</td>
                <td>4%</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="600" data-aos-once="true">
                <td>Asset Turnover</td>
                <td>43%</td>
                <td>44%</td>
                <td>41%</td>
                <td>9%</td>
                <td>29%</td>
                <td>118%</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="700" data-aos-once="true">
                <td>Gearing</td>
                <td>0.26</td>
                <td>0.49</td>
                <td>0.41</td>
                <td>0.46</td>
                <td>0.46</td>
                <td>0.39</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>P/E Ordinary (Class N)</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>49.05</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="900" data-aos-once="true">
                <td>P/E Non-voting (Class X)</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>40.22</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1000" data-aos-once="true">
                <td>PBV</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>1.06</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="financial-h-q-sub-container"
        data-aos="fade-right"
        data-aos-duration="500"
        data-aos-once="true"
      >
        <h3>
          Financial Highlights for Q1 <br />
          FY 2023/24
        </h3>
        <div className="financial-h-q-table-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  Crop Solutions
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  Agri Produce
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  Livestock Solutions
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="900"
                  data-aos-once="true"
                >
                  Industrial Solutions
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  Healthcare & Personal
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1500"
                  data-aos-once="true"
                >
                  Group
                </th>
              </tr>
            </thead>

            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr data-aos="fade-up" data-aos-delay="500" data-aos-once="true">
                <td>ROA</td>
                <td>7%</td>
                <td>6%</td>
                <td>4%</td>
                <td>3%</td>
                <td>3%</td>
                <td>4%</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="600" data-aos-once="true">
                <td>Asset Turnover</td>
                <td>51%</td>
                <td>57%</td>
                <td>38%</td>
                <td>14%</td>
                <td>29%</td>
                <td>118%</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="700" data-aos-once="true">
                <td>Gearing</td>
                <td>0.11</td>
                <td>0.17</td>
                <td>0.22</td>
                <td>0.04</td>
                <td>0.11</td>
                <td>0.36</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>P/E Ordinary (Class N)</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>23.07</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="900" data-aos-once="true">
                <td>P/E Non-voting (Class X)</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>17.60</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1000" data-aos-once="true">
                <td>PBV</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>0.79</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default FinancialHighlightsQ;
