import React from 'react'
import FinancialHighlightsHome from '../templates/FinancialHighlights/FinancialHighlightsHome'

function FinancialHighlights() {
  return (
    <div>
        <FinancialHighlightsHome />
    </div>
  )
}

export default FinancialHighlights
