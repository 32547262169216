import React from 'react'
import './FinancialHighlightsHome.css'
import FinancialMainBanner from './FinancialMainBanner';
import SegmentalRevenue from './SegmentalRevenue';
import NetSegmentalResult from './NetSegmentalResult';
import FinancialHighlightsQ from './FinancialHighlightsQ';

const FinancialHighlightsHome = () => {
  return (
    <div>
      <FinancialMainBanner />
      <SegmentalRevenue />
      <NetSegmentalResult />
      <FinancialHighlightsQ />
    </div>
  )
}

export default FinancialHighlightsHome;
